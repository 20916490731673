import React from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA } from '../utils/styles'

const ScImageSize = css`
  height: 400px;
  ${MEDIA.TABLET`
    height: 300px;
  `}
  ${MEDIA.MOBILE`
    height: 200px;
  `}
`

const ScWrapper = styled.div`
  position: relative;
  ${ScImageSize}
`
const ScImage = styled(GatsbyImage)`
  width: auto;
  ${ScImageSize}
  img {
    object-position: bottom;
  }
`

const ScDetailWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
`

const ScDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: var(--space-2xl) var(--size-gutter);
  ${MEDIA.DESKTOP`
    width: var(--size-max-width);
    margin: 0 auto;
  `}
`

const ScTitle = styled.h1`
  font-family: var(--font-cormorantSc);
  font-weight: var(--extrabold);
  line-height: var(--solid);
  margin: 0 0 var(--space-xl);
  font-size: var(--text-title);
  text-transform: uppercase;
  display: none;
`

const ScContent = styled.p`
  font-size: var(--text-lead);
  font-weight: var(--semibold);
  margin: 0;
`

function Header({ image, title, content }) {
  return (
    <ScWrapper>
      <ScImage image={image.gatsbyImageData} alt={image.title} />
      <ScDetailWrapper>
        <ScDetail>
          <ScTitle>{title}</ScTitle>
          {content && <ScContent>{content}</ScContent>}
        </ScDetail>
      </ScDetailWrapper>
    </ScWrapper>
  )
}

export default Header
