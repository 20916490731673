import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import MenuTemplate from '../components/menu/menuTemplate'
import Header from '../components/menu/header'

class MenuIndex extends React.Component {
  render() {
    const categories = get(this, 'props.data.allContentfulCategory.nodes')
    const headerImage = get(this, 'props.data.contentfulHome.menuHeaderImage')

    return (
      <Layout title="Our Menu" location={this.props.location}>
        <Header image={headerImage} title="The Fat Cow Menu" />
        <MenuTemplate categories={categories} />
      </Layout>
    )
  }
}

export default MenuIndex

export const pageQuery = graphql`
  query CategoryQuery {
    contentfulHome(contentful_id: { eq: "6uAUZR10UxlXBcl5oTkzs" }) {
      menuHeaderImage {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          quality: 90
          formats: PNG
        )
      }
    }
    allContentfulCategory(sort: { fields: index, order: ASC }) {
      nodes {
        name
        icon {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 50
            quality: 80
            formats: WEBP
          )
        }
        menu {
          active
          name
          slug
          price
          ingredient
          image {
            title
            gatsbyImageData(
              placeholder: BLURRED
              width: 300
              formats: WEBP
              quality: 90
            )
          }
        }
      }
    }
  }
`
