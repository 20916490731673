import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA, COLORS } from '../utils/styles'

const ScMenuItem = styled.div`
  color: black;
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 3px 3px 5px ${COLORS.BG};
  overflow: hidden;
  height: 320px;
  ${MEDIA.MOBILE`
    height: 300px;
  `}
`

const ScMenuImage = styled(GatsbyImage)`
  height: 150px;
  width: auto;
  box-shadow: 2px 2px 5px;
  img {
    border-radius: 10px 10px 0 0;
  }
`

const ScMenuDetail = styled.div`
  margin: 15px;
`

const ScMenuTitle = styled.h3`
  color: black;
  font-weight: bold;
  margin: 0 0 10px;
  ${MEDIA.MOBILE`
    font-size: 14px;
  `}

  ${MEDIA.SMALLEST`
    font-size: 12px;
  `}
`

const ScMenuDescription = styled.div`
  font-size: var(--text-xs);
  color: black;
  ${MEDIA.SMALLEST`
    font-size: 10px;
  `}
`

const ScMenuPrice = styled.div`
  font-weight: bold;
  font-size: var(--text-md);
  color: ${COLORS.ORANGE};
  position: absolute;
  bottom: 20px;
  ${MEDIA.MOBILE`
    font-size: 17px;
    bottom: 15px;
  `}
  ${MEDIA.SMALLEST`
    font-size: 15px;
    bottom: 10px;
  `}
`

const MenuItem = ({ name, image, ingredient, price, onClick }) => {
  return (
    <ScMenuItem key={name} onClick={onClick}>
      <ScMenuImage image={image.gatsbyImageData} alt={name} />
      <ScMenuDetail>
        <ScMenuTitle>{name}</ScMenuTitle>
        <ScMenuDescription>{ingredient}</ScMenuDescription>
        <ScMenuPrice>{price} THB</ScMenuPrice>
      </ScMenuDetail>
    </ScMenuItem>
  )
}

export default MenuItem
