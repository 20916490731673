import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MEDIA, COLORS } from '../utils/styles'
import MenuItem from './menuItem'
import { GatsbyImage } from 'gatsby-plugin-image'

const ScWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: var(--size-max-width);
  margin: 0 auto;
  padding: 0px var(--size-gutter) 100px;
  background-color: ${COLORS.BLACK_BG};
  ${MEDIA.DESKTOP`
    padding-top: 90px;
    padding-bottom: 50px;
    margin-top:  50px;
    border-radius: 10px;
  `}
  ${MEDIA.TABLET`
    padding-bottom: 150px;
  `}
`

const ScIcon = styled(({ isActive, isHovering, ...props }) => (
  <GatsbyImage {...props} />
))`
  height: 50px;
  width: 50px;

  ${MEDIA.MOBILE`
    height: 40px;
    width: 40px;
  `}
`

const ScCategoryHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  margin-bottom: 30px;
  background-color: ${COLORS.ORANGE};
  display: flex;
  justify-content: center;
  ${MEDIA.TABLET`
    display:none;
  `}
  ${MEDIA.MOBILE`
    display:none;
  `}
`

const ScCategorySticky = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: ${COLORS.ORANGE};
  display: flex;
  justify-content: center;
  border-radius: 15px 15px 0 0;

  ${MEDIA.MOBILE`
    height: 70px;
  `}

  ${MEDIA.DESKTOP`
    display:none;
  `}
`

const ScCategoryButton = styled.div`
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  color: black;
  font-size: 15px;

  ${MEDIA.DESKTOP`
    width: 90px;
    margin:0 10px;
    ${(props) =>
      props.isHovering &&
      `
        font-weight:bold;
    `}
  `}
  ${MEDIA.TABLET`
    margin-right: 0px;
    width: 110px;
  `}
  ${MEDIA.MOBILE`
    font-size: 12px;
    width: 80px;
    
  `}
  ${MEDIA.SMALLEST`
    font-size: 11px;
    width: 65px;
    font-size: 10px;
  `}

  
  font-weight:bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ScCategoryWrapper = styled.div`
  ${MEDIA.DESKTOP`
    padding-top: 50px;
  `}
  ${MEDIA.TABLET`
    padding-top: 50px;
  `}
  ${MEDIA.MOBILE`
    padding-top: 50px;
  `}
`

const ScCategoryTitle = styled.p`
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-weight: bold;
  font-size: 40px;

  ${MEDIA.DESKTOP`
    font-size: 45px;
  `}
`

const ScMenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  ${MEDIA.TABLET`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${MEDIA.MOBILE`
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  `}
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 5px 5px 5px ${COLORS.ORANGE_TRS};
  overflow: hidden;
  color: white;
  z-index: 100;
`
const ScMenuImage = styled(GatsbyImage)`
  img {
    border-radius: 10px 10px 0 0;
  }
`

const CategoryButton = ({ name, icon }) => {
  const [isHovering, setIsHovering] = useState(false)
  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }
  return (
    <ScCategoryButton
      onClick={() => {
        const element = document.getElementById(name)
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }}
      isHovering={isHovering}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ScIcon
        image={icon.gatsbyImageData}
        alt={icon.title}
        isHovering={isHovering}
      />
      {name}
    </ScCategoryButton>
  )
}

const MenuTemplate = ({ categories }) => {
  const [activeCat, setActiveCat] = useState(categories[0].name)
  const [menuImage, setMenuImage] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  // activeCat changed
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [activeCat])

  // prevent scrolling
  useEffect(() => {
    const { body } = document
    body.style.overflow = isOpen ? 'hidden' : 'initial'

    return () => {
      body.style.overflow = 'initial'
    }
  }, [isOpen])

  const filtersCategories = categories.filter((category) => {
    return !!category.menu
  })

  const categoryList = filtersCategories.map((category) => {
    return {
      categoryName: category.name,
      menuList: filtersCategories.find(({ name }) => name === category.name)
        .menu,
    }
  })

  return (
    <ScWrapper>
      <ScCategoryHeader>
        {filtersCategories.map((category) => {
          return (
            <CategoryButton
              key={'cat_button_' + category.name}
              name={category.name}
              icon={category.icon}
            />
          )
        })}
      </ScCategoryHeader>

      {categoryList.map((category) => {
        return (
          <ScCategoryWrapper
            key={'category_' + category.categoryName}
            id={category.categoryName}
          >
            <ScCategoryTitle>{category.categoryName}</ScCategoryTitle>
            <ScMenuGrid>
              {category.menuList.map((menu) => {
                return (
                  <MenuItem
                    key={menu.name}
                    name={menu.name}
                    image={menu.image}
                    ingredient={menu.ingredient}
                    price={menu.price}
                    onClick={() => {
                      setIsOpen(true)
                      setMenuImage(menu.image)
                    }}
                  />
                )
              })}
            </ScMenuGrid>
          </ScCategoryWrapper>
        )
      })}

      <ScCategorySticky>
        {filtersCategories.map((category) => {
          return (
            <CategoryButton
              key={'cat_button_' + category.name}
              name={category.name}
              icon={category.icon}
              isActive={category.name === activeCat}
              setActiveCat={setActiveCat}
            />
          )
        })}
      </ScCategorySticky>
      {isOpen && (
        <Overlay
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <ModalWrapper>
            <ScMenuImage image={menuImage.gatsbyImageData} />
          </ModalWrapper>
          click anywhere to close!
        </Overlay>
      )}
    </ScWrapper>
  )
}

export default MenuTemplate
